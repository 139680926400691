import * as React from 'react';
import type { Editor } from '@tiptap/react';
import { Button } from '#stories/Button/Button';
import { Dropdown } from '#stories/Dropdown/Dropdown';
import { DropdownItem } from '#stories/Dropdown/DropdownItem/DropdownItem';
import { Icon } from '#stories/Icon/Icon';
import { Tooltip } from '#stories/Tooltip/Tooltip';

const FONT_SIZES = [12, 14, 16, 18, 20, 24, 28, 32, 36, 40, 48, 56, 64];
const DEFAULT_FONT_SIZE = '16px';

interface FontSizeStyleProps {
  editor: Editor;
}

const FontSizeStyle = ({ editor }: FontSizeStyleProps) => {
  const currentFontSize = React.useMemo(() => {
    const marks = editor.state.selection.$head.marks();
    const fontSizeMark = marks.find((mark) => mark.type.name === 'fontSize');
    return fontSizeMark?.attrs.fontSize || DEFAULT_FONT_SIZE;
  }, [editor]);

  const setFontSize = (size: number) => {
    editor.chain().focus().setFontSize(`${size}px`).run();
  };

  return (
    <Dropdown
      items={FONT_SIZES.map((size) => (
        <DropdownItem
          key={size}
          onClick={() => setFontSize(size)}
          className="cursor-pointer"
        >
          {size}px
        </DropdownItem>
      ))}
    >
      <Tooltip mainText="Font Size">
        <Button variant="secondary" size="xs">
          <div className="flex items-center text-dark-60">
            {currentFontSize}
            <Icon iconName="bottom" />
          </div>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

export default FontSizeStyle;
