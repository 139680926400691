import * as React from 'react';
import type { Editor } from '@tiptap/react';
import type { FormatAction } from '../../types';
import TiptapButton from '../ui/TextEditorButton';
import { ReactComponent as BoldIcon } from '../../icons/bold.svg';
import { ReactComponent as ItalicIcon } from '../../icons/italic.svg';
import { ReactComponent as UnderlineIcon } from '../../icons/underline.svg';

type TextStyleAction =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'code'
  | 'clearFormatting';

interface TextStyle extends FormatAction {
  value: TextStyleAction;
}

const FORMAT_ACTIONS: TextStyle[] = [
  {
    value: 'bold',
    label: 'Bold',
    icon: <BoldIcon />,
    action: (editor) => editor.chain().focus().toggleBold().run(),
    isActive: (editor) => editor.isActive('bold'),
    canExecute: (editor) => editor.can().chain().focus().toggleBold().run(),
    shortcuts: ['mod', 'B'],
  },
  {
    value: 'italic',
    label: 'Italic',
    icon: <ItalicIcon />,
    action: (editor) => editor.chain().focus().toggleItalic().run(),
    isActive: (editor) => editor.isActive('italic'),
    canExecute: (editor) => editor.can().chain().focus().toggleItalic().run(),
    shortcuts: ['mod', 'I'],
  },
  {
    value: 'underline',
    label: 'Underline',
    icon: <UnderlineIcon />,
    action: (editor) => editor.chain().focus().toggleUnderline().run(),
    isActive: (editor) => editor.isActive('underline'),
    canExecute: (editor) =>
      editor.can().chain().focus().toggleUnderline().run(),
    shortcuts: ['mod', 'U'],
  },
];

interface TextStyleProps {
  editor: Editor;
}

export const TextStyle: React.FC<TextStyleProps> = ({ editor }) => {
  return (
    <>
      {FORMAT_ACTIONS.map(
        ({ value, label, icon, action, isActive, canExecute }) => (
          <TiptapButton
            key={value}
            active={isActive(editor)}
            disabled={!canExecute(editor)}
            onClick={() => action(editor)}
            tooltipProps={{
              mainText: label,
            }}
          >
            {icon}
          </TiptapButton>
        ),
      )}
    </>
  );
};

export default TextStyle;
