import type { Editor } from '@tiptap/react';
import TiptapButton from '../ui/TextEditorButton';
import { ReactComponent as BulletListIcon } from '../../icons/bulletList.svg';
import { ReactComponent as OrderedListIcon } from '../../icons/orderedList.svg';

interface ListStyleProps {
  editor: Editor;
}

const ListStyle = ({ editor }: ListStyleProps) => {
  return (
    <div className="flex gap-1">
      <TiptapButton
        onClick={() => {
          editor.chain().focus().toggleBulletList().run();
        }}
        tooltipProps={{
          mainText: 'Bullet list',
        }}
      >
        <BulletListIcon />
      </TiptapButton>
      <TiptapButton
        onClick={() => {
          editor.chain().focus().toggleOrderedList().run();
        }}
        tooltipProps={{
          mainText: 'Ordered list',
        }}
      >
        <OrderedListIcon />
      </TiptapButton>
    </div>
  );
};

export default ListStyle;
