import type { Editor } from '@tiptap/react';
import type { TextEditorProps } from '../TextEditor';

export const getOutput = (
  editor: Editor,
  format: TextEditorProps['output'],
): object | string => {
  switch (format) {
    case 'json':
      return editor.getJSON();
    case 'html':
      return editor.isEmpty ? '' : editor.getHTML();
    default:
      return editor.getText();
  }
};

export type CssProperties = Record<string, string | null | undefined>;

export const extractCssAttributeFromString = (
  style: string | null,
  attributeName: string,
): string | null => {
  if (!style) return null;

  const regex = new RegExp(`${attributeName}:\\s*([^;]+)`, 'i');
  const match = style.match(regex);
  if (!match) return null;

  const value = match[1].trim();
  if (!value) return null;

  return value;
};

export const applyStyleProperty = (
  propertyName: string,
  value: string | null | undefined,
): CssProperties => {
  if (!value) return {};
  return { style: `${propertyName}: ${value}` };
};

export const getLinkParseRule = () => {
  return [
    { tag: 'a[href]:not([data-type="button"]):not([href *= "javascript:" i])' },
  ];
};
