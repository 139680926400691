import { Extension } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { STYLING_KEYSTROKES } from '../utils/constants';

export const DisableStylingShortcuts = Extension.create({
  name: 'disableStylingShortcuts',

  addKeyboardShortcuts() {
    const shortcuts: Record<string, () => boolean> = {};

    STYLING_KEYSTROKES.forEach((shortcut) => {
      shortcuts[shortcut] = () => true;
    });

    return shortcuts;
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('disableStylingKeystrokes'),
        props: {
          handleKeyDown: (view, event) => {
            const isModKey = event.ctrlKey || event.metaKey;

            if (isModKey) {
              const key = event.key.toLowerCase();
              const shortcut = `mod-${key}`;

              if (STYLING_KEYSTROKES.includes(shortcut)) {
                event.preventDefault();
                return true;
              }
            }

            return false;
          },
        },
      }),
    ];
  },
});
