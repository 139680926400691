import { TextStyle } from '@tiptap/extension-text-style';
import {
  applyStyleProperty,
  extractCssAttributeFromString,
} from '../../utils/utils';

export const BackgroundColor = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        parseHTML: (element: HTMLElement) =>
          extractCssAttributeFromString(
            element.getAttribute('style'),
            'background-color',
          ),
        renderHTML: (attributes: { backgroundColor: string | null }) =>
          applyStyleProperty('background-color', attributes.backgroundColor),
      },
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setBackgroundColor:
        (color: string | null) =>
        ({ chain }: { chain: () => any }) => {
          return chain().setMark(this.name, { backgroundColor: color }).run();
        },
      unsetBackgroundColor:
        () =>
        ({ chain }: { chain: () => any }) => {
          return chain().setMark(this.name, { backgroundColor: null }).run();
        },
    };
  },
});
