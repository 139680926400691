import * as React from 'react';
import type { Editor } from '@tiptap/react';
import { ReactComponent as LeftAligmentIcon } from '../../icons/leftAligment.svg';
import { ReactComponent as CenterAligmentIcon } from '../../icons/centerAligment.svg';
import { ReactComponent as RightAligmentIcon } from '../../icons/rightAligment.svg';
import TiptapButton from '../ui/TextEditorButton';

interface TextAlignStyleProps {
  editor: Editor;
}

const TextAlignStyle = ({ editor }: TextAlignStyleProps) => {
  const [, forceUpdate] = React.useState({});

  const currentAlign = React.useMemo(() => {
    if (editor.isActive({ textAlign: 'center' })) return 'center';
    if (editor.isActive({ textAlign: 'right' })) return 'right';
    return 'left';
  }, [editor, editor.state.selection]);

  const setTextAlign = (align: string) => {
    // @ts-ignore - The setTextAlign command exists in the custom TextAlign extension
    editor.chain().focus().setTextAlign(align).run();
    forceUpdate({});
  };

  return (
    <>
      <TiptapButton
        tooltipProps={{ mainText: 'Left align' }}
        onClick={() => setTextAlign('left')}
        active={currentAlign === 'left'}
      >
        <LeftAligmentIcon />
      </TiptapButton>
      <TiptapButton
        tooltipProps={{ mainText: 'Center align' }}
        onClick={() => setTextAlign('center')}
        active={currentAlign === 'center'}
      >
        <CenterAligmentIcon />
      </TiptapButton>
      <TiptapButton
        tooltipProps={{ mainText: 'Right align' }}
        onClick={() => setTextAlign('right')}
        active={currentAlign === 'right'}
      >
        <RightAligmentIcon />
      </TiptapButton>
    </>
  );
};

export default TextAlignStyle;
