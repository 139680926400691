import * as React from 'react';
import type { Editor } from '@tiptap/react';
import TiptapButton from '../ui/TextEditorButton';
import { ReactComponent as UndoIcon } from '../../icons/undo.svg';
import { ReactComponent as RedoIcon } from '../../icons/redo.svg';

interface UndoRedoProps {
  editor: Editor;
}

const UndoRedo: React.FC<UndoRedoProps> = ({ editor }) => {
  return (
    <div className="flex gap-1">
      <TiptapButton
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        tooltipProps={{
          mainText: 'Undo',
        }}
      >
        <UndoIcon />
      </TiptapButton>
      <TiptapButton
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        tooltipProps={{
          mainText: 'Redo',
        }}
      >
        <RedoIcon />
      </TiptapButton>
    </div>
  );
};

export default UndoRedo;
